import AXIOS from 'axios';
import { store } from '../state';
import { isProduction } from '../constants';

enum API_HOSTNAMES {
  DEV = 'devapi.benchtools.net',
  PROD = 'api.benchtools.net',
}

const getHostname = () => {
  if (isProduction) {
    return API_HOSTNAMES.PROD;
  }
  return API_HOSTNAMES.DEV;
};

const hostname = getHostname();

const axios = AXIOS.create({
  baseURL: `https://${hostname}/`,
});

axios.interceptors.request.use((config) => {
  const userToken = store.getState().auth.user?.token;
  if (userToken) {
    config.headers = {
      Authorization: `Bearer ${userToken}`,
    };
  }
  return config;
});

export { axios };
